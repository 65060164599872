import styled from "styled-components";

const SkeletonMainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 500px;
  padding: 16px 40px 16px 40px;
`;

export { SkeletonMainContainer };

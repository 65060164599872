import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import {
  DrawerMainContainer,
  DrawerRelativeContainer,
  DrawerShadow,
  DrawerAbsoluteContainer,
  DrawerHeader,
  DrawerShapeIconContainer,
  DrawerContentContainer,
  DrawerTitleContainer,
} from "./styles";
import { DrawerProps } from "./types";

const Drawer = ({ title, content, open, onClose }: DrawerProps) => (
  <DrawerMainContainer open={open}>
    <DrawerRelativeContainer>
      {open ? (
        <DrawerShadow onClick={() => (open ? onClose(false) : null)} />
      ) : null}
      <DrawerAbsoluteContainer>
        <DrawerHeader>
          <DrawerTitleContainer>
            <Typography
              variant="headline7"
              color="var(--color-neutral-10)"
              style={{ marginLeft: 5 }}
            >
              {title}
            </Typography>
          </DrawerTitleContainer>
          <DrawerShapeIconContainer
            onClick={() => (open ? onClose(false) : null)}
          >
            <ShapeIcon
              name="IconX"
              variant="transparent"
              size={40}
              color="var(--color-neutral-40)"
            />
          </DrawerShapeIconContainer>
        </DrawerHeader>
        <DrawerContentContainer>{content}</DrawerContentContainer>
      </DrawerAbsoluteContainer>
    </DrawerRelativeContainer>
  </DrawerMainContainer>
);

export { Drawer };

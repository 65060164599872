import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { EmojiButtonMainContainer } from "./styles";
import { EmojiButtonProps } from "./types";

const EmojiButton = ({
  emojiIcon,
  selected,
  disabled,
  onClick,
}: EmojiButtonProps) => (
  <EmojiButtonMainContainer
    selected={selected}
    disabled={disabled}
    onClick={() => (disabled ? null : onClick())}
  >
    <Icons
      name={emojiIcon}
      size={24}
      color={
        selected && disabled
          ? "var(--color-neutral-70)"
          : selected
          ? "var(--color-secondary-50)"
          : "var(--color-neutral-10)"
      }
    />
  </EmojiButtonMainContainer>
);

export { EmojiButton };

import { Skeleton as SkeletonUI } from "@flash-tecnologia/hros-web-ui-v2";
import { SkeletonMainContainer } from "./styles";

const Skeleton = () => (
  <SkeletonMainContainer>
    <SkeletonUI variant="rounded" height="500px" width="100%" />
    <SkeletonUI variant="rounded" height="54px" width="100%" />
  </SkeletonMainContainer>
);

export { Skeleton };

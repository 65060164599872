export default {
  translations: {
    header: {
      performanceText: "completed profile",
    },
    notification: {
      title: "Notifications",
      firstTab: "My company",
      secondTab: "My app",
      thirdTab: "Flash Notifications",
      emptyMessageTitle: "You don't have any notifications",
      emptyMessageText: "Your notifications will be displayed here.",
      detailButton: "See details",
      detailHeaderTitle: "Communication details",
      image: "Notification image",
      reactButtonLabel: "React",
      reaction: {
        default: "React",
        heart: "Loved",
        like: "Liked",
        support: "Support",
        congrats: "Congratulations",
      },
      research: "Survey",
      researchSendButton: "Send",
      researchButtonLabel: "Answer",
      researchButtonLabelAnswered: "See answers",
      researchPlaceholder: "Leave a comment",
      researchAlertToast: "Leave your reaction to proceed",
      researchErrorToast: "Leave your reaction to proceed",
      researchModal: {
        title: "Yay, thanks for sharing your opinion!",
        principalMessage: "Your responses are very important to us",
        secondaryMessage:
          "When you provide feedback, we learn a lot and it helps us to find improvements and new ways to ensure your well-being.",
        button: "Go to homepage",
      },
      anonymousTag: "Anonymous response",
      anonymousInfo:
        "Your response is completely anonymous, your identity will not be recorded.",
      deadlineAlert: {
        title: "This survey is closed",
        description:
          "This survey cannot be displayed as it is closed and will no longer accept responses.",
      },
      close: "Close",
      createdAt: "Sent on",
      comment: "Comment",
    },
  },
};

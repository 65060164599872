import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const RatingFieldMainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const RatingFieldButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const RatingFieldLegendsContainer = styled(RatingFieldButtonsContainer)`
  justify-content: space-between;
  margin-top: 8px;
`;

const RatingFieldLegend = styled(Typography)<{
  disabled?: boolean;
  active?: boolean;
}>`
  font-weight: 600 !important;
  color: ${({ disabled, active, theme }) =>
    active && disabled
      ? theme?.colors?.neutral70
      : active
      ? theme?.colors?.secondary50
      : theme?.colors?.neutral30} !important;
`;

export {
  RatingFieldMainContainer,
  RatingFieldButtonsContainer,
  RatingFieldLegendsContainer,
  RatingFieldLegend,
};

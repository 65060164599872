export default {
  translations: {
    header: {
      performanceText: "do perfil completo",
    },
    notification: {
      title: "Notificações",
      firstTab: "Minha empresa",
      thirdTab: "Notificações da Flash",
      secondTab: "Meu app",
      emptyMessageTitle: "Você ainda não possui notificações",
      emptyMessageText: "Suas notificações serão exibidas aqui.",
      detailButton: "Ver detalhes",
      detailHeaderTitle: "Detalhes da comunicação",
      image: "Imagem da notificação",
      reactButtonLabel: "React",
      reaction: {
        default: "Reagir",
        heart: "Amei",
        like: "Gostei",
        support: "Apoio",
        congrats: "Parabéns",
      },
      research: "Pesquisa",
      researchSendButton: "Enviar",
      researchButtonLabel: "Responder",
      researchButtonLabelAnswered: "Ver respostas",
      researchPlaceholder: "Deixar comentário",
      researchAlertToast: "Deixe sua reação para progresseguir",
      researchErrorToast: "Deixe sua reação para progresseguir",
      researchModal: {
        title: "Eba, valeu por compartilhar sua opinião!",
        principalMessage: "Suas respostas são super importantes para nós",
        secondaryMessage:
          "Quando você registra seu feedback, aprendemos muito e isso nos ajuda a buscar melhorias e novas formas para garantir seu bem-estar.",
        button: "Ir para a página inicial",
      },
      anonymousTag: "Resposta anônima",
      anonymousInfo:
        "Sua resposta é completamente anônima, sua identidade não será registrada.",
      deadlineAlert: {
        title: "Essa pesquisa está encerrada",
        description:
          "Não foi possível exibir essa pesquisa, pois ela está encerrada e não receberá mais respostas.",
      },
      close: "Fechar",
      createdAt: "Enviado em",
      comment: "Comentário",
    },
  },
};

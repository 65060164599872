import {
  Button,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import {
  SurveyExpiredMessageMainContainer,
  SurveyExpiredMessageContentContainer,
  SurveyExpiredMessageDescriptionContainer,
  SurveyExpiredMessageButtonContainer,
} from "./styles";
import { SurveyExpiredMessageProps } from "./types";

const SurveyExpiredMessage = ({ onGoBack }: SurveyExpiredMessageProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "notification",
  });

  return (
    <SurveyExpiredMessageMainContainer>
      <SurveyExpiredMessageContentContainer>
        <ShapeIcon
          name="IconMoodSad"
          variant="default"
          size={84}
          color="var(--color-secondary-50)"
        />
        <Typography
          variant="headline7"
          variantColor="var(--color-secondary-50)"
        >
          {t("deadlineAlert.title")}
        </Typography>
        <SurveyExpiredMessageDescriptionContainer>
          <Typography variant="body3" variantColor="var(--color-neutral-40)">
            {t("deadlineAlert.description")}
          </Typography>
        </SurveyExpiredMessageDescriptionContainer>
      </SurveyExpiredMessageContentContainer>
      <SurveyExpiredMessageButtonContainer>
        <Button variant="primary" size="large" onClick={onGoBack}>
          {t("close")}
        </Button>
      </SurveyExpiredMessageButtonContainer>
    </SurveyExpiredMessageMainContainer>
  );
};
export { SurveyExpiredMessage };

import { useState, useCallback, useEffect } from "react";
import {
  Icons,
  IconsProps,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useNavigate } from "react-router-dom";
import { ChangeCompany } from "../../ChangeCompany";
import { dispatchLogout, checkBenefitsAndRedirectHome } from "@/utils";
import {
  AccessItem,
  ChangeAccessText,
  EmailText,
  Item,
  StyledMenu,
  Tag,
} from "./styled";
import { getAuthenticatedUser } from "@flash-tecnologia/hros-web-utility";

type Option = {
  iconName: IconsProps["name"];
  label: string;
  onClick: () => void;
};

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [changeCompanyVisible, setChangeCompanyVisible] = useState(false);
  const [user, setUser] = useState<{ name: string; email: string }>();

  const handleSetVisible = useCallback(
    () => setChangeCompanyVisible(!changeCompanyVisible),
    [changeCompanyVisible]
  );

  useEffect(() => {
    const getUser = async () => {
      const user = await getAuthenticatedUser();
      setUser({ name: user.name, email: user.email });
    };

    getUser();
  }, []);

  useEffect(() => {
    window.addEventListener("changeAccountMenu", handleSetVisible);
    return () => {
      window.removeEventListener("changeAccountMenu", handleSetVisible);
    };
  }, []);

  const closeMenu = () => setAnchorEl(null);

  const onProfileMenuButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const onChangeCompanyClick = () => {
    setChangeCompanyVisible(!changeCompanyVisible);
    closeMenu();
  };

  const onAccessSelected = () => {
    checkBenefitsAndRedirectHome();
    setChangeCompanyVisible(false);
  };

  const options: Option[] = [
    {
      iconName: "IconUser",
      label: "Minha conta",
      onClick: () => navigate(`/employees/person`),
    },
    {
      iconName: "IconDoorExit",
      label: "Sair",
      onClick: () => dispatchLogout(),
    },
  ];

  const employeeOptions = options.map(({ iconName, label, onClick }, index) => {
    return (
      <Item
        key={`profile-menu-item-${index}`}
        onClick={() => {
          onClick();
          closeMenu();
        }}
      >
        <Icons name={iconName} fill="none" size={16} />
        <Typography variant="body4" weight={700} children={label} />
      </Item>
    );
  });

  return (
    <>
      <button onClick={onProfileMenuButtonClick}>
        <Tag variant="primary">
          <Icons name="IconUserCircle" size={16} fill="transparent" />
          <Icons name="IconCaretDown" size={16} fill="transparent" />
        </Tag>
      </button>

      <StyledMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <AccessItem>
          <Typography
            variant="body4"
            // The profile page (/employees/me/profile) overrides the font-weight
            style={{ fontWeight: 700 }}
            children={user?.name}
          />
          <EmailText variant="body4" children={user?.email} />
          <LinkButton variant="default" onClick={onChangeCompanyClick}>
            <ChangeAccessText variant="caption" style={{ fontWeight: 700 }}>
              Acessar outra conta Flash{" "}
              <Icons name="IconArrowsLeftRight" size={16} />
            </ChangeAccessText>
          </LinkButton>
        </AccessItem>
        {employeeOptions}
      </StyledMenu>
      <ChangeCompany
        visible={changeCompanyVisible}
        setVisible={() => setChangeCompanyVisible(!changeCompanyVisible)}
        onAccessSelected={onAccessSelected}
      />
    </>
  );
};

export default ProfileDropdown;

import { Typography } from "@mui/material";
import styled from "styled-components";

const RadioFieldMainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const RadioContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};
`;

const RadioLabel = styled(Typography)<{
  checked?: boolean;
  disabled?: boolean;
}>`
  color: ${({ checked, disabled, theme }) =>
    disabled && checked
      ? theme?.colors?.neutral70
      : checked
      ? theme?.colors?.secondary40
      : theme?.colors?.neutral40} !important;
`;

export { RadioFieldMainContainer, RadioContainer, RadioLabel };

import styled from "styled-components";

const EmojiButtonMainContainer = styled.div<{
  selected?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  height: 64px;
  cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};
  border-style: solid;
  border-width: ${({ theme }) => theme?.borders?.width?.xs2};
  border-radius: ${({ theme }) => theme?.borders?.radius?.xs};
  background-color: ${({ selected, disabled, theme }) =>
    selected && disabled
      ? theme?.colors?.neutral95
      : selected
      ? theme?.colors?.secondary99
      : theme?.colors?.neutral100};
  border-color: ${({ selected, disabled, theme }) =>
    selected && disabled
      ? theme?.colors?.neutral90
      : selected
      ? theme?.colors?.primary
      : theme?.colors?.neutral90};

  :hover {
    border-color: ${({ disabled, selected, theme }) =>
      disabled
        ? null
        : selected
        ? theme?.colors?.primary
        : theme?.colors?.secondary95};
    background-color: ${({ theme, disabled }) =>
      disabled ? null : theme?.colors?.secondary95};

    > svg {
      stroke: ${({ theme, disabled }) =>
        disabled ? null : theme?.colors?.secondary50};
    }
  }
`;

export { EmojiButtonMainContainer };

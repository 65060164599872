import { useEffect, useState } from "react";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { ContractFlow } from "./flows/contract-flow";
import { AmendmentFlow } from "./flows/amendment-flow";
import {
  getAmendmentSignedUrl,
  getContractByCompanyAndType,
  getContractSignedUrl,
  signAmendment,
  signContract,
} from "@/api/contract-manager.service";
import { useFlag } from "@flash-tecnologia/feature-flags";
import { setEventTracking } from "@/utils";

type Contract = {
  id: string;
  pdfUrl: string;
};

export type Amendment = {
  id: string;
  pdfUrl: string;
  contractId: string;
  type: "benefit" | "expense";
};

export const ContractModal = () => {
  const isAmendmentFlowEnabled = useFlag({
    flagName: "FLASH_OS_AMENDMENT_FLOW",
  });
  const { selectedCompany } = useSelectedCompany();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState<Contract | undefined>();
  const [amendments, setAmendments] = useState<Amendment[]>([]);

  const onClose = () => {
    setOpen(false);
  };

  const onSignContract = async () => {
    try {
      setLoading(true);
      await signContract({ contractId: contract.id });
      setEventTracking("contract_modal_accept_click", { product: "benefit" });
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const onSignAmendment = async () => {
    try {
      setLoading(true);

      await Promise.all(
        amendments.map(async (amendment) => {
          return signAmendment({
            amendmentId: amendment.id,
            contractId: amendment.contractId,
          });
        })
      );

      setEventTracking("contract_amendment_modal_accept_click", {
        products: amendments.map((amendment) => amendment.type).toString(),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getContract() {
      setAmendments([]);

      const contracts = await getContractByCompanyAndType({
        companyId: selectedCompany.id,
      });

      if (!contracts?.length) return;

      const benefitsContract = contracts.find(
        (contract) =>
          contract.type === "benefits" &&
          contract.status === "WAITING_SIGNATURE"
      );

      if (benefitsContract) {
        const contractSignedUrl = await getContractSignedUrl({
          contractId: benefitsContract.id,
        });

        setContract({
          id: benefitsContract.id,
          pdfUrl: contractSignedUrl.url,
        });
        setOpen(true);
        setEventTracking("contract_modal_view", { bu: "benefit" });
        return;
      }

      if (!isAmendmentFlowEnabled) return;

      const expenseContract = contracts.find((contract) =>
        contract.planIds.some((planId) => planId.includes("expense_saas"))
      );

      const pendingAmendments: Omit<Amendment, "pdfUrl">[] = [];
      const expenseAmendmentToSign = expenseContract?.amendments.find(
        (amendment) => !amendment.isSigned
      );

      if (expenseAmendmentToSign) {
        pendingAmendments.push({
          id: expenseAmendmentToSign.id,
          contractId: expenseContract.id,
          type: "expense",
        });
      }

      const benefitsAmendmentToSign = benefitsContract?.amendments.find(
        (amendment) => !amendment.isSigned
      );

      if (benefitsAmendmentToSign) {
        pendingAmendments.push({
          id: benefitsAmendmentToSign.id,
          contractId: benefitsContract.id,
          type: "benefit",
        });
      }

      if (pendingAmendments.length === 0) return;

      const amendmentsSignedUrls = await Promise.all(
        pendingAmendments.map(async (amendment) => {
          const amendmentSignedUrl = await getAmendmentSignedUrl({
            amendmentId: amendment.id,
            contractId: amendment.contractId,
          });
          return amendmentSignedUrl;
        })
      );

      const amendmentsToShow = pendingAmendments.map((amendment, index) => {
        return {
          ...amendment,
          pdfUrl: amendmentsSignedUrls[index].url,
        };
      });

      setAmendments(amendmentsToShow);
      setOpen(true);
      setEventTracking("contract_amendment_modal_view", {
        products: amendments.map((amendment) => amendment.type).toString(),
      });
    }

    getContract();
  }, [selectedCompany?.id, isAmendmentFlowEnabled]);

  return (
    <>
      {contract && (
        <ContractFlow
          open={open}
          loading={loading}
          onSign={onSignContract}
          pdfUrl={contract.pdfUrl}
        />
      )}
      {amendments.length > 0 && (
        <AmendmentFlow
          open={open}
          loading={loading}
          amendments={amendments}
          onSign={onSignAmendment}
          onClose={onClose}
        />
      )}
    </>
  );
};

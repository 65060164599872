import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const FeelingSurveyFormMainContainer = styled.div`
  width: 100%;
`;

const FeelingSurveyFormContainer = styled.div`
  width: 100%;
  padding: 16px 40px 16px 40px;
`;

const FeelingSurveyFormDateText = styled(Typography)`
  color: ${({ theme }) => theme?.colors?.neutral40};
  font-weight: 600;
`;

const FeelingSurveyFormTextAreaContainer = styled.div`
  width: 100%;

  > div {
    width: 100% !important;
  }
`;

const FeelingSurveyFormButtonContainer = styled.div`
  width: 100%;
  padding: 16px 40px 16px 40px;
  border-top-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme?.colors?.neutral90};

  > button {
    width: 100%;
  }
`;

export {
  FeelingSurveyFormMainContainer,
  FeelingSurveyFormContainer,
  FeelingSurveyFormDateText,
  FeelingSurveyFormTextAreaContainer,
  FeelingSurveyFormButtonContainer,
};

import styled from "styled-components";

const SurveyExpiredMessageMainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const SurveyExpiredMessageContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: calc(50% - 110px);
`;

const SurveyExpiredMessageDescriptionContainer = styled(
  SurveyExpiredMessageContentContainer
)`
  max-width: 275px;
  text-align: center;
  margin-top: 0;
  gap: 0;
`;

const SurveyExpiredMessageButtonContainer = styled.div`
  width: 100%;
  padding: 16px 40px 16px 40px;
  border-top-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme?.colors?.neutral90};

  > button {
    width: 100%;
  }
`;

export {
  SurveyExpiredMessageMainContainer,
  SurveyExpiredMessageContentContainer,
  SurveyExpiredMessageDescriptionContainer,
  SurveyExpiredMessageButtonContainer,
};

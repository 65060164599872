import { EmojiButton } from "./components";
import {
  RatingFieldButtonsContainer,
  RatingFieldLegendsContainer,
  RatingFieldMainContainer,
  RatingFieldLegend,
} from "./styles";
import { RatingFieldProps, EmojiIcon } from "./types";

const RatingField = ({
  value,
  icons,
  legend,
  disabled,
  onChange,
}: RatingFieldProps) => (
  <RatingFieldMainContainer>
    <RatingFieldButtonsContainer>
      {icons?.map((e: EmojiIcon, i) => {
        const newValue = i + 1;
        return (
          <EmojiButton
            key={`${e}-${newValue}`}
            emojiIcon={e}
            selected={newValue === value}
            onClick={() => onChange(newValue)}
            disabled={disabled}
          />
        );
      })}
    </RatingFieldButtonsContainer>
    <RatingFieldLegendsContainer>
      <RatingFieldLegend
        variant="body4"
        disabled={disabled}
        active={value === 1}
      >
        {legend?.left}
      </RatingFieldLegend>
      <RatingFieldLegend
        variant="body4"
        disabled={disabled}
        active={value === icons?.length}
      >
        {legend?.right}
      </RatingFieldLegend>
    </RatingFieldLegendsContainer>
  </RatingFieldMainContainer>
);

export { RatingField };

import styled from "styled-components";

const DrawerMainContainer = styled.div<{ open?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  z-index: 3 !important;
  margin-right: ${({ open }) => (open ? 0 : "-100%")};
  transition: 0.5s;
`;

const DrawerRelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const DrawerShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3b2e37;
  opacity: 0.65;
`;

const DrawerAbsoluteContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  width: 671px;
  height: 100%;
  max-width: 100%;
  background-color: var(--color-neutral-100);
`;

const DrawerHeader = styled.div`
  position: relative;
  padding: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-neutral-90);
`;

const DrawerShapeIconContainer = styled.div`
  border: 1px solid var(--color-neutral-80);
  border-radius: 100%;
  cursor: pointer;

  svg {
    cursor: pointer;
  }
`;

const DrawerContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 92px);
  overflow-x: auto;
`;

const DrawerTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  DrawerMainContainer,
  DrawerRelativeContainer,
  DrawerShadow,
  DrawerAbsoluteContainer,
  DrawerHeader,
  DrawerShapeIconContainer,
  DrawerContentContainer,
  DrawerTitleContainer,
};

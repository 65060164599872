import { QuestionMainContainer, QuestionTitle } from "./styles";
import { QuestionProps } from "./types";

const Question = ({ title, children, hideBorder }: QuestionProps) => (
  <QuestionMainContainer hideBorder={hideBorder}>
    <QuestionTitle variant="headline9">{title}</QuestionTitle>
    {children}
  </QuestionMainContainer>
);

export { Question };

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const QuestionMainContainer = styled.div<{ hideBorder?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-bottom-width: ${({ hideBorder }) => (hideBorder ? 0 : "1px")};
  border-style: solid;
  border-color: ${({ theme }) => theme?.colors?.neutral90};
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

const QuestionTitle = styled(Typography)`
  color: ${({ theme }) => theme?.colors?.neutral20} !important;
  font-weight: 700 !important;
  margin-bottom: 14px;
`;

export { QuestionMainContainer, QuestionTitle };

import { Radio } from "@flash-tecnologia/hros-web-ui-v2";
import { RadioLabel, RadioContainer, RadioFieldMainContainer } from "./styles";
import { RadioFieldProps } from "./types";

const RadioField = ({
  value,
  options,
  disabled,
  onChange,
}: RadioFieldProps) => (
  <RadioFieldMainContainer>
    {options?.map((option, i) => (
      <RadioContainer
        key={`radio-${i}`}
        disabled={disabled}
        onClick={() => (disabled ? null : onChange(option?.value))}
      >
        <Radio disabled={disabled} checked={option?.value === value} />
        <RadioLabel disabled={disabled} checked={option?.value === value}>
          {option?.label}
        </RadioLabel>
      </RadioContainer>
    ))}
  </RadioFieldMainContainer>
);

export { RadioField };

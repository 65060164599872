import { TagFieldMainContainer, StyleTag } from "./styles";
import { TagFieldProps } from "./types";

const TagField = ({
  tags,
  selectedTags,
  disabled,
  onChange,
}: TagFieldProps) => (
  <TagFieldMainContainer>
    {tags?.map((tag, i) => {
      const selected = selectedTags?.some(
        (selectedTag) => selectedTag === tag?.value
      );
      const variant = selected ? "primary" : "gray";
      return (
        <StyleTag
          key={`tag-${i}`}
          variant={variant}
          disabled={disabled}
          selected={selected}
          onClick={() =>
            disabled
              ? null
              : onChange({
                  tag,
                  selectedTags: selected
                    ? selectedTags?.filter(
                        (selectedTag) => selectedTag !== tag?.value
                      )
                    : [...selectedTags, tag?.value],
                })
          }
        >
          {tag?.label}
        </StyleTag>
      );
    })}
  </TagFieldMainContainer>
);

export { TagField };

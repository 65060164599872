import { useEffect, useState } from "react";
import { Button, dayjs, TextArea } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { answerSurvey, getSurveyById } from "@/api";
import { RatingField, TagField, RadioField, SurveyExpiredMessage } from "../";
import { Question, Skeleton } from "./components";
import {
  FeelingSurveyFormDateText,
  FeelingSurveyFormContainer,
  FeelingSurveyFormMainContainer,
  FeelingSurveyFormTextAreaContainer,
  FeelingSurveyFormButtonContainer,
} from "./styles";
import { FeelingSurveyFormProps, Section } from "./types";

export default ({
  surveyId,
  active,
  date,
  disabled,
  onFinish,
}: FeelingSurveyFormProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "notification",
  });

  const [submitted, setSubmitted] = useState(false);
  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState({ form: false, button: false });
  const [section, setSection] = useState<Section>();
  const [answers, setAnswers] = useState<{ id: string; value: any }[]>([]);

  useEffect(() => {
    if (active && !section) {
      setLoading({ ...loading, form: true });
      getSurveyById(surveyId)
        .then((e) => {
          const status = e?.details?.status;
          const deadline = e?.survey?.interactionDeadline;
          const isExpired = deadline
            ? dayjs()?.startOf("day")?.isAfter(dayjs(deadline)?.startOf("day"))
            : false;

          if (isExpired && status !== "completed") {
            setExpired(true);
          } else {
            const section = e?.survey?.sections[0];

            if (section) {
              setSection(section);
              setAnswers(
                section?.questions?.map((q) => ({
                  id: q?.id,
                  value: q?.userAnswer?.value,
                })) || []
              );
            }
          }
        })
        .finally(() => setLoading({ ...loading, form: false }));
    }
  }, [active, surveyId]);

  const handleSubmit = () => {
    setLoading({ ...loading, button: true });
    answerSurvey({
      draft: false,
      surveyId,
      sections: [
        {
          id: section?.id,
          questions: answers?.filter((q) => q?.value),
        },
      ],
    })
      .then(() => {
        setSubmitted(true);
        if (onFinish) onFinish();
      })
      .finally(() => setLoading({ ...loading, button: false }));
  };

  if (loading?.form) return <Skeleton />;
  if (expired) return <SurveyExpiredMessage onGoBack={() => onFinish()} />;

  return (
    <FeelingSurveyFormMainContainer>
      <FeelingSurveyFormContainer>
        <FeelingSurveyFormDateText variant="caption">
          {`${t("createdAt")} ${dayjs(date)?.format("DD/MM/YYYY")}`}
        </FeelingSurveyFormDateText>
        {section?.questions?.map((question) => {
          if (question?.answerFormat === "emoji")
            return (
              <Question title={question?.title}>
                <RatingField
                  disabled={disabled || submitted}
                  icons={[
                    "IconMoodCry",
                    "IconMoodSad",
                    "IconMoodEmpty",
                    "IconMoodSmile",
                    "IconMoodHappy",
                  ]}
                  legend={{
                    left: question?.lowerLabel,
                    right: question?.upperLabel,
                  }}
                  value={answers?.find((q) => q?.id === question?.id)?.value}
                  onChange={(e) =>
                    setAnswers((prevAnswers) => [
                      ...prevAnswers?.map((q) =>
                        q?.id === question?.id ? { ...q, value: e } : q
                      ),
                    ])
                  }
                />
              </Question>
            );
          if (
            question?.answerFormat === "multiple-choice" &&
            question?.options?.length
          )
            return (
              <Question title={question?.title}>
                <RadioField
                  disabled={disabled || submitted}
                  options={question?.options?.map((o) => ({
                    label: o?.value,
                    value: o?.id,
                  }))}
                  value={answers?.find((q) => q?.id === question?.id)?.value}
                  onChange={(e) =>
                    setAnswers((prevAnswers) => [
                      ...prevAnswers?.map((q) =>
                        q?.id === question?.id ? { ...q, value: e } : q
                      ),
                    ])
                  }
                />
              </Question>
            );
          if (
            question?.answerFormat === "multi-select" &&
            question?.options?.length
          )
            return (
              <Question title={question?.title}>
                <TagField
                  disabled={disabled || submitted}
                  tags={question?.options?.map((o) => ({
                    label: o?.value,
                    value: o?.id,
                  }))}
                  selectedTags={
                    answers?.find((q) => q?.id === question?.id)?.value || []
                  }
                  onChange={({ selectedTags }) =>
                    setAnswers((prevAnswers) => [
                      ...prevAnswers?.map((q) =>
                        q?.id === question?.id
                          ? { ...q, value: selectedTags }
                          : q
                      ),
                    ])
                  }
                />
              </Question>
            );
          if (question?.answerFormat === "text")
            return (
              <Question title={question?.title} hideBorder>
                <FeelingSurveyFormTextAreaContainer>
                  <TextArea
                    disabled={disabled || submitted}
                    placeholder={t("comment")}
                    maxLength={200}
                    value={answers?.find((q) => q?.id === question?.id)?.value}
                    onChange={(e) =>
                      setAnswers([
                        ...answers?.map((q) =>
                          q?.id === question?.id
                            ? { ...q, value: e?.currentTarget?.value }
                            : q
                        ),
                      ])
                    }
                  />
                </FeelingSurveyFormTextAreaContainer>
              </Question>
            );

          return <></>;
        })}
      </FeelingSurveyFormContainer>
      <FeelingSurveyFormButtonContainer>
        {disabled || submitted ? (
          <Button variant="primary" size="large" onClick={() => onFinish()}>
            {t("close")}
          </Button>
        ) : (
          <Button
            loading={loading?.button}
            disabled={section?.questions
              ?.filter((q) => q?.required)
              ?.some((q) =>
                answers?.some((a) => a?.id === q?.id && a?.value === undefined)
              )}
            variant="primary"
            size="large"
            onClick={handleSubmit}
          >
            {t("researchSendButton")}
          </Button>
        )}
      </FeelingSurveyFormButtonContainer>
    </FeelingSurveyFormMainContainer>
  );
};

import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const TagFieldMainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
`;

const StyleTag = styled(Tag)<{ selected?: boolean }>`
  border-width: 1px;
  border-style: solid;
  color: ${({ selected, disabled, theme }) =>
    selected && disabled
      ? theme?.colors?.neutral70
      : selected
      ? theme?.colors?.secondary40
      : theme?.colors?.neutral40} !important;
  border-color: ${({ selected, disabled, theme }) =>
    selected && disabled
      ? theme?.colors?.neutral90
      : selected
      ? theme?.colors?.secondary50
      : theme?.colors?.neutral40} !important;
  background-color: ${({ selected, disabled, theme }) =>
    selected && disabled
      ? theme?.colors?.neutral90
      : selected
      ? theme?.colors?.secondary99
      : theme?.colors?.neutral100} !important;

  :hover {
    color: ${({ theme }) => theme?.colors?.secondary50} !important;
    border-color: ${({ selected, theme }) =>
      selected
        ? theme?.colors?.primary
        : theme?.colors?.secondary95} !important;
    background-color: ${({ theme }) => theme?.colors?.secondary95} !important;
  }
`;

export { TagFieldMainContainer, StyleTag };

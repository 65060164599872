import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Loader } from "@flash-tecnologia/hros-web-ui-v2";
import { useFlag } from "@flash-tecnologia/feature-flags";
import { useTranslation } from "react-i18next";
import { LoaderContainer, MainContainer } from "./styled";
import {
  GroupedByTime,
  InboxMessageWithGroup,
  NotificationFeedProps,
} from "./types";
import {
  Header,
  Tabs,
  EmptyMessage,
  Skeleton,
  NotificationList,
} from "./components";
import IconEmpty from "../../assets/IconEmpty.svg";
import SectionLoading from "../SectionLoading";
import { Context } from "@/context";
import { InboxHeaderList } from "@flash-hros/home";
export default ({
  visible,
  setVisible,
  notifications,
  onScroll,
  onClose,
}: NotificationFeedProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "notification",
  });
  const inboxEnabled = useFlag({ flagName: "FLASH_OS_INBOX" });

  const inboxResponseMetadata = useRef<{
    isLoading: boolean;
    metadata?: {
      totalCount: number;
      totalPages: number;
      currentPage: number;
      nextPage: number | null;
      prevPage: number | null;
    };
  }>({ isLoading: false });

  const [formattedInboxMessages, setFormattedInboxMessages] = useState<
    Record<GroupedByTime, InboxMessageWithGroup[]>
  >({
    today: [],
    yesterday: [],
    others: [],
  });

  const [inboxQuery, setQuery] = useState<{
    page: number;
    limit: number;
  }>({
    page: 1,
    limit: 10,
  });
  const [selectedTab, setSelectedTab] = useState(0);

  const { loading } = useContext(Context);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const $feed = document?.getElementById("notification-feed");

  useEffect(() => {
    if (selectedTab === 0) {
      if (notifications?.length && scrollTop > 0 && onScroll) onScroll();
    } else if (selectedTab === 1) {
      if (!inboxResponseMetadata.current.isLoading) {
        const nextPage = inboxResponseMetadata.current.metadata?.nextPage;
        setQuery((oldValue) => ({
          page: nextPage,
          limit: oldValue.limit,
        }));
      }
    }
  }, [scrollTop]);

  const handleScroll = () => {
    if (onScroll && !loading?.urls?.includes("getEmployeeNotifications")) {
      const currentScrollTop = $feed.scrollTop;
      if (currentScrollTop === 0) return;
      if (currentScrollTop > scrollTop + 300) setScrollTop(currentScrollTop);
    }
  };

  const onCloseFeed = () => {
    if (visible && onClose) onClose();
    setVisible(!visible);
  };

  const renderNotifications = useCallback(() => {
    return (
      <NotificationList notifications={notifications} onSubmit={onCloseFeed} />
    );
  }, [notifications?.length]);

  return (
    <MainContainer
      id="notification-feed"
      visible={visible}
      onScroll={handleScroll}
    >
      <Header title={t("title")} onClose={onCloseFeed} />
      <Tabs
        onTabChanged={(index) => setSelectedTab(index)}
        tabItens={[
          {
            label: t("firstTab"),
            component: (
              <>
                <SectionLoading url="getEmployeeNotifications">
                  {({ loading }) =>
                    loading ? (
                      notifications?.length ? (
                        <>
                          {renderNotifications()}
                          <LoaderContainer>
                            <Loader variant="primary" size="small" />
                          </LoaderContainer>
                        </>
                      ) : (
                        <Skeleton />
                      )
                    ) : !notifications?.length ? (
                      <EmptyMessage
                        headerElement={<IconEmpty />}
                        title={t("emptyMessageTitle")}
                        message={t("emptyMessageText")}
                      />
                    ) : (
                      renderNotifications()
                    )
                  }
                </SectionLoading>
              </>
            ),
          },
          ...(inboxEnabled
            ? [
                {
                  label: t("thirdTab"),
                  component: (
                    <>
                      <InboxHeaderList
                        key={
                          formattedInboxMessages.today.length +
                          formattedInboxMessages.yesterday.length +
                          formattedInboxMessages.others.length
                        }
                        formattedInboxMessages={formattedInboxMessages}
                        setFormattedInboxMessages={setFormattedInboxMessages}
                        inboxResponseMetadata={inboxResponseMetadata}
                        query={inboxQuery}
                        opt={{
                          invertTitle: false,
                          uniqueList: false,
                          showAckAll: true,
                        }}
                      />
                    </>
                  ),
                },
              ]
            : []),
        ]}
      />
    </MainContainer>
  );
};
